<!--土木学会保密协议，二维码识别-->
<template>
  <div style="padding: 0 5px;overflow: hidden">
    <h1 style="text-align: center">
      外部人员保密承诺书
    </h1>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      我了解有关保密法规制度和川藏铁路现场保密行为规范，知悉应当承担的保密义务和法律责任。本人郑重承诺：
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      一、认真遵守国家保密法律、法规和规章制度，履行保密义务。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      二、不该问的绝对不问（工程点位、进度、计划、人员等）。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      三、不该看的绝对不看（工程各类介质如文件、资料、图片、照片、电脑显示器内容等）。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      四、不该说的绝对不说（任何自己可能知悉的秘密信息和内部信息）。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      五、不以任何方式泄露所接触、知悉的国家秘密和工作秘密。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      六、未经建设单位审查批准，不擅自发表涉及未公开工作内容的文章、著述。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      七、不拍摄川藏铁路施工现场、工程展板、施工图纸等资料。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      八、不对外传播川藏铁路隧道和特殊结构桥梁等施工现场图片和相关视频（快手、抖音、朋友圈、语音视频等）。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      九、不以任何形式买卖川藏铁路建设相关信息。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      十、不使用苹果、ChatGPT、特斯拉等有定位、数据收集功能的国外电子设备、软件和车辆。
    </div>
    <div style="text-indent: 2em;margin-bottom: 2% /* 2em 大约等于两个空格的宽度 */">
      违反上述承诺，自愿接受党纪、政纪和法律惩处，并承担相应的法律责任。
    </div>

    <div style="margin-left: 50%;margin-top: 20%;display: flex;position: relative">
      <div  style="margin-top: 3%">
        承诺人签名：
      </div>
      <div v-if="flag">
        <el-button type="text" @click="gotoSignature">点击签名</el-button>
      </div>
      <div v-if="!flag" style="transform: rotate(-90deg);width: 45%;position: absolute;top: -350%;left: 60%">
            <img style="width: 100%" :src="resultImg" alt="">
      </div>
    </div>
    <div style="margin-left: 50%;margin-top: 5%;margin-bottom: 10%;">
      {{ date }}
    </div>

<!--    签字弹窗-->
    <div style="position: absolute;width: 100%;height: 100vh;background: #FFFFFF;top: 9%;overflow: hidden" v-if="!flag1">
<!--      <el-dialog v-model="sginatrueDialogVisible" title="" >-->
        <div  class="box">
          <vue-esign ref="esign" :width="400" :height="1000" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
                     :bgColor.sync="bgColor" />
          <div style="transform: rotate(90deg);margin-top: -25%;margin-left: -130%;">
            <el-button  type="danger" @click="handleReset">重置签名</el-button>
            <el-button type="success" @click="handleGenerate">提交签名</el-button>
          </div>
        </div>
<!--      </el-dialog>-->
    </div>

  </div>
</template>

<script>
import {Encrypt} from "@/utils/secret";
import {ElMessage} from "element-plus";
import {setNowDateNoTwo} from "@/utils/time";

export default {
  name: "",
  data() {
    return {
      date: '',
      flag: true,
      flag1: true,
      resultImg: '',
      sginatrueDialogVisible: false,
      lineWidth: 8,
      lineColor: "#000000",
      bgColor: "",
      isCrop: false,
    }
  },
  created() {
    // if (this.resultImg === ''){
    //   this.flag = true
    // }else {
    //   this.flag = false
    //   this.resultImg = this.$route.params.name_url
    //   ElMessage.success('签名成功,请直接点击左上角×退出!')
    // }
    this.date = setNowDateNoTwo(new Date)
  },
  methods: {
    gotoSignature(){
      // this.$router.push({
      //   path: '/signature'
      // })
      this.flag1 = false
      this.sginatrueDialogVisible = true
    },
    handleReset() {
      this.$refs.esign.reset();
    },
    handleGenerate() {
      this.$refs.esign
          .generate({ format: "png", quality: 0.8 })
          .then((res) => {
            this.resultImg = res;
            this.axios.post('/secrecySignature/saveSignature', (response) => {
              if (response.obj){
                this.sginatrueDialogVisible = false
                this.flag = false
                this.flag1 = true
                //this.resultImg = this.$route.params.name_url
                ElMessage.success('签名成功,请直接点击左上角×退出!')
                // this.$router.push({
                //   name: 'File_index',
                //   params: {
                //     name_url: this.resultImg
                //   }
                // });
              }
            },{
              name_url: this.resultImg
            })
          })
          .catch((err) => {
            console.error(err);
            // alert("生成图片失败：" + err.message);
            ElMessage.error('签名失败!')
          });
    }
  }
}
</script>

<style scoped>
.box {
  width: 50%;
  height: 65vh;
  border: 1px solid red;
  margin-left: 25%;
  margin-top: 20%;
}
</style>